import React from 'react';

const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94"
    height="16"
    viewBox="0 0 94 16"
    fill="none"
    {...props}
  >
    <path
      d="M4.76662 15.8353C3.1909 15.8353 1.41782 15.3531 0 14.546L0.963849 12.6234C2.25995 13.3517 3.53633 13.7061 4.86532 13.7061C5.94102 13.7061 7.17461 13.5715 7.17461 12.5348C7.17461 11.6785 6.3884 11.4062 4.51334 11.0814C1.47046 10.5696 0.338838 9.68048 0.338838 7.80055C0.338838 5.70409 1.86191 4.5 4.5199 4.5C5.86535 4.5 7.98713 4.91999 9.12533 5.60568L8.17793 7.57091C7.10223 6.97379 5.71732 6.63255 4.35871 6.63255C3.28302 6.63255 2.73695 6.95734 2.73695 7.60038C2.73695 8.30577 3.38172 8.56169 5.19428 8.93243C8.80626 9.69686 9.53324 10.8386 9.53324 12.4363C9.53324 14.5656 7.75028 15.8386 4.76333 15.8386L4.76662 15.8353Z"
      fill="#070B12"
    />
    <path
      d="M15.9779 15.8746C12.8232 15.8746 10.6191 13.519 10.6191 10.1463V4.71973H12.9778V10.5105C12.9778 12.4166 14.2114 13.7453 15.9779 13.7453C17.7444 13.7453 18.9583 12.4166 18.9583 10.5105V4.71973H21.3169V10.1463C21.3169 13.519 19.1227 15.8746 15.9779 15.8746Z"
      fill="#070B12"
    />
    <path
      d="M28.7769 15.855C25.2636 15.855 22.8096 13.4337 22.8096 9.96586V1.40947L25.2077 0.87793V5.95667L25.3393 5.83532C26.2768 4.97574 27.6091 4.48029 28.9973 4.48029C32.2836 4.48029 34.7607 6.91797 34.7607 10.1496C34.7607 13.3812 32.2441 15.8583 28.7736 15.8583L28.7769 15.855ZM28.7769 6.60956C26.6419 6.60956 25.2077 8.03017 25.2077 10.1463C25.2077 12.2625 26.6847 13.7454 28.7966 13.7454C30.9086 13.7454 32.3658 12.2723 32.3658 10.166C32.3658 8.05972 30.925 6.60627 28.7769 6.60627V6.60956Z"
      fill="#070B12"
    />
    <path
      d="M40.2676 15.8353C38.6919 15.8353 36.9188 15.3531 35.501 14.546L36.4648 12.6234C37.7609 13.3517 39.0373 13.7061 40.3663 13.7061C41.442 13.7061 42.6756 13.5715 42.6756 12.5348C42.6756 11.6785 41.8894 11.4062 40.0143 11.0814C36.9714 10.5696 35.8398 9.68048 35.8398 7.80055C35.8398 5.70409 37.3629 4.5 40.0209 4.5C41.3663 4.5 43.4881 4.91999 44.6263 5.60568L43.6789 7.57091C42.6032 6.97379 41.2183 6.63255 39.8597 6.63255C38.784 6.63255 38.2379 6.95734 38.2379 7.60038C38.2379 8.30577 38.8827 8.56169 40.6953 8.93243C44.3072 9.69686 45.0342 10.8386 45.0342 12.4363C45.0342 14.5656 43.2513 15.8386 40.2643 15.8386L40.2676 15.8353Z"
      fill="#070B12"
    />
    <path
      d="M58.7288 4.47754C57.7814 4.47754 56.9327 4.72364 56.232 5.16983L56.2024 4.72032H54.1201V15.6324H56.4788V9.50054C56.4788 7.52875 57.2584 6.61009 58.9295 6.61009C59.8769 6.61009 60.6697 7.03331 61.0907 7.42045L62.3704 5.76697C61.3835 4.90083 60.1894 4.48082 58.7256 4.48082L58.7288 4.47754Z"
      fill="#070B12"
    />
    <path
      d="M67.9137 15.8552C64.6274 15.8552 62.1504 13.4273 62.1504 10.2088C62.1504 6.9903 64.6604 4.48047 68.1177 4.48047C71.5751 4.48047 74.085 6.91158 74.085 10.3926V15.6353H71.7066V14.3756L71.575 14.5035C70.677 15.3631 69.3447 15.8585 67.917 15.8585L67.9137 15.8552ZM68.098 6.60973C66.0091 6.60973 64.5485 8.08289 64.5485 10.1892C64.5485 12.2955 65.9828 13.7489 68.1177 13.7489C70.2527 13.7489 71.6869 12.3282 71.6869 10.2121C71.6869 8.09595 70.2099 6.61302 68.098 6.61302V6.60973Z"
      fill="#070B12"
    />
    <path
      d="M88.0358 15.8552C84.7495 15.8552 82.2725 13.4273 82.2725 10.2088C82.2725 6.9903 84.7824 4.48047 88.2398 4.48047C91.6972 4.48047 94.2071 6.91158 94.2071 10.3926V15.6353H91.8288V14.3756L91.6972 14.5035C90.7991 15.3631 89.4668 15.8585 88.0391 15.8585L88.0358 15.8552ZM88.2201 6.60973C86.1312 6.60973 84.6706 8.08289 84.6706 10.1892C84.6706 12.2955 86.1048 13.7489 88.2398 13.7489C90.3747 13.7489 91.809 12.3282 91.809 10.2121C91.809 8.09595 90.332 6.61302 88.2201 6.61302V6.60973Z"
      fill="#070B12"
    />
    <path
      d="M51.9036 13.5751C51.6075 13.6703 51.2786 13.7261 50.9299 13.7261C49.2555 13.7261 48.4758 12.8042 48.4758 10.8357V9.62507V6.81008H51.7161L52.2457 4.72014H48.4725V4.69723V0.875L46.1172 1.40654V4.69723V3.8409V10.0778C46.1172 13.8278 48.0153 15.8521 50.7259 15.8521C51.5286 15.8521 52.2457 15.7274 52.897 15.4682L51.9036 13.5719V13.5751Z"
      fill="#070B12"
    />
    <path
      d="M81.3616 13.5751C81.0655 13.6703 80.7366 13.7261 80.3879 13.7261C78.7135 13.7261 77.9339 12.8042 77.9339 10.8357V9.62507V6.81008H81.1741L81.7037 4.72014H77.9306V4.69723V0.875L75.5752 1.40654V4.69723V10.9341C75.5752 13.8278 77.4733 15.8521 80.1839 15.8521C80.9866 15.8521 81.7037 15.7274 82.355 15.4682L81.3616 13.5719V13.5751Z"
      fill="#070B12"
    />
  </svg>
);

export default LogoIcon;
