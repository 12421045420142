import React, { useMemo, useRef, useState } from 'react';
import { useClickOutside } from '../../hooks';
import { ArrowDownIcon, CheckedIcon } from './icons';
import { Scrollbar } from '../Scrollbar';

export interface SelectOption {
  value: string;
  label: React.ReactNode;
  dropdownLabel?: React.ReactNode;
  isHidden?: boolean;
}

export interface SelectProps {
  options?: SelectOption[];
  disabled?: boolean;
  placeholder?: React.ReactNode;
  value?: string;
  width?: number;
  dropdownWidth?: number;
  className?: string;
  onChange?: (value: string) => void;
  renderDropdownContent?: () => React.ReactNode;
}

const Select: React.FC<SelectProps> = ({
  value,
  options,
  disabled = false,
  placeholder,
  width,
  dropdownWidth,
  className,
  onChange,
  renderDropdownContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const selectedOption = useMemo(
    () => options?.find((option) => option.value === value),
    [value, options]
  );

  const attrs = {
    container: {
      className: `select${isExpanded ? ' select--expanded' : ''}${
        value ? ' select--selected' : ''
      }${className ? ` ${className}` : ''}`,
      ref: containerRef,
      ...(typeof width === 'number'
        ? { style: { width, minWidth: width } }
        : {}),
    },
    field: {
      disabled,
      type: 'button' as const,
      className: 'select__field',
      onClick: () => {
        setIsExpanded(!isExpanded);
      },
    },
    text: {
      className: 'select__text',
    },
    fieldIcon: {
      className: 'select__field-icon',
    },
    dropdown: {
      className: 'select__dropdown',
      ...(typeof dropdownWidth === 'number'
        ? { style: { width: dropdownWidth, minWidth: dropdownWidth } }
        : {}),
    },
    scrollbar: {
      className: 'select__scrollable',
    },
    checkedIcon: {
      className: 'select__checked-icon',
    },
  };

  useClickOutside(containerRef, () => {
    setIsExpanded(false);
  });

  const renderOptions = () =>
    options?.map((option, index) => {
      const isSelected = option.value === selectedOption?.value;

      const props = {
        key: index,
        type: 'button' as const,
        disabled: isSelected || disabled,
        className: `select__option${
          option.isHidden ? ' select__option--hidden' : ''
        }`,
        'aria-selected': isSelected,
        onClick: () => {
          onChange?.(option.value);
          setIsExpanded(false);
        },
      };

      return (
        <button {...props}>
          <span {...attrs.text}>{option.dropdownLabel || option.label}</span>
          {isSelected ? <CheckedIcon {...attrs.checkedIcon} /> : null}
        </button>
      );
    });

  return (
    <div {...attrs.container}>
      <button {...attrs.field}>
        <span {...attrs.text}>
          {selectedOption?.label || placeholder || ''}
        </span>
        <ArrowDownIcon {...attrs.fieldIcon} />
      </button>
      <div {...attrs.dropdown}>
        <Scrollbar {...attrs.scrollbar}>
          {renderDropdownContent?.()}
          {renderOptions()}
        </Scrollbar>
      </div>
    </div>
  );
};

export default Select;
