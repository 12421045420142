import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import axios from '../../../../axios';
import { SIGNUP_URL } from '../../../../constants/config';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Button, ButtonSize } from '../../../../components';
import { CheckedIcon, SpinnerIcon, WarningIcon } from './icons';
import { ROUTES } from '../../../../routes';
import { updatePaymentConfirmationLoading } from '../../../../store/auth/auth.slice';

export interface CheckoutFormProps {
  totalPrice: number;
}

const PAYMENT_FORM_ID = 'payment';

const CheckoutForm: React.FC<CheckoutFormProps> = ({ totalPrice }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const selectedPlan = useAppSelector((state) => state.auth.selectedPlan);
  const trialUsedStatus = useAppSelector((state) => state.auth.trialUsedStatus);
  const isPaymentConfirmationLoading = useAppSelector(
    (state) => state.auth.isLoading.paymentConfirmation
  );

  const stripe = useStripe();
  const elements = useElements();

  const [isConfirmError, setIsConfirmError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPaymentDetailsComplete, setIsPaymentDetailsComplete] = useState(
    false
  );

  // TODO: good practice to move out logic;
  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsConfirmError(false);

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    if (elements) {
      const { error: submitError } = await elements.submit();

      if (submitError) {
        return;
      }

      // make a call, recieve type, and secret
      try {
        dispatch(updatePaymentConfirmationLoading(true));

        const body = {
          productId: selectedPlan?.stripePlanInfo.productId,
          priceId: selectedPlan?.stripePlanInfo.priceId,
          quantity: selectedPlan?.usersCount,
        };

        const response = await axios.post(
          `/services/billing/stripe/orgs/${user?.orgId}/users/${user?.userId}/subscription`,
          body
        );

        const { payload } = response.data.payload;

        const confirmIntent =
          payload?.type === 'setup'
            ? stripe.confirmSetup
            : stripe.confirmPayment;

        const { error } = await confirmIntent({
          elements,
          clientSecret: payload?.customerSecret,
          confirmParams: {
            return_url: `${SIGNUP_URL}${ROUTES.PROCESSING}`,
          },
        });

        if (error) {
          setIsConfirmError(true);
          return;
        }

        setIsSuccess(true);
      } catch (error) {
        setIsConfirmError(true);
      } finally {
        dispatch(updatePaymentConfirmationLoading(false));
      }
    }
  };

  const attrs = {
    container: {
      className: 'checkout-form',
    },
    form: {
      id: PAYMENT_FORM_ID,
      className: `checkout-form__form${
        trialUsedStatus ? '' : ' checkout-form__form--trial'
      }`,
      onSubmit: handleSubmit,
    },
    paymentElement: {
      onChange: (event: StripePaymentElementChangeEvent) => {
        setIsPaymentDetailsComplete(event.complete);
      },
      options: {
        terms: {
          card: 'never' as const,
        },
      },
    },
    trialDisclaimer: {
      className: 'checkout-form__trial-disclaimer',
    },
    trialDisclaimerItem: {
      className: 'checkout-form__trial-disclaimer-item',
    },
    buttonWrapper: {
      className: 'checkout-form__button-wrapper',
    },
    button: {
      className: `checkout-form__button${
        !stripe || !elements || !isPaymentDetailsComplete
          ? ' checkout-form__button--disabled'
          : ''
      }${isSuccess ? ' checkout-form__button--success' : ''}`,
      type: 'submit' as const,
      size: ButtonSize.S,
      form: PAYMENT_FORM_ID,
      disabled:
        !stripe ||
        !elements ||
        !isPaymentDetailsComplete ||
        isPaymentConfirmationLoading ||
        isSuccess,
    },
    buttonSpinnerIcon: {
      className: 'checkout-form__button-spinner-icon',
    },
    error: {
      className: `checkout-form__error${
        isConfirmError ? ' checkout-form__error--visible' : ''
      }`,
    },
  };

  const renderButtonIcon = () => {
    if (isSuccess) {
      return <CheckedIcon />;
    }

    if (isPaymentConfirmationLoading) {
      return <SpinnerIcon {...attrs.buttonSpinnerIcon} />;
    }

    return null;
  };

  const renderButtonText = () => {
    if (isSuccess) {
      return null;
    }

    if (isPaymentConfirmationLoading) {
      return <span>Processing</span>;
    }

    return <span>Continue</span>;
  };

  return (
    <div {...attrs.container}>
      <form {...attrs.form}>
        <PaymentElement {...attrs.paymentElement} />
        {trialUsedStatus ? null : (
          <div {...attrs.trialDisclaimer}>
            <div {...attrs.trialDisclaimerItem}>
              <span>Total Due Today:</span>
              <span>$0.00</span>
            </div>
            <div {...attrs.trialDisclaimerItem}>
              <span>After 30 days:</span>
              <span>${totalPrice.toFixed(2)}</span>
            </div>
          </div>
        )}
      </form>
      <div {...attrs.buttonWrapper}>
        <Button {...attrs.button}>
          {renderButtonIcon()}
          {renderButtonText()}
        </Button>
        <div {...attrs.error}>
          <WarningIcon />
          <span>
            Your credit card was declined. Try using a different payment method.
          </span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
