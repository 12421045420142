import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { sendUninstallExtension } from '../../store/auth/auth.thunks';
import { withPageTransition } from '../../hoc';
import { LinkIcon, LogoIcon } from './icons';
import { Background } from '../../components';

const UninstallExtension: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const { trackEvent, isMixPanelInitialized } = useMixPanel();
  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  const dispatch = useAppDispatch();

  const { search } = useLocation();

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('uninstall-extension');
    }
  }, [isMixPanelInitialized, trackEvent]);

  useEffect(() => {
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('event', 'uninstall-extension');
    }
  }, [isGoogleAnalyticsInitialized, trackGAEvent]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const extensionVersion = queryParams.get('v');

    if (user && extensionVersion) {
      dispatch(sendUninstallExtension({ extensionVersion }));
    }
  }, [dispatch, user, search]);

  const attrs = {
    container: {
      className: 'uninstall-extension',
    },
    content: {
      className: 'uninstall-extension__content',
    },
    logo: {
      className: 'uninstall-extension__logo',
    },
    title: {
      className: 'uninstall-extension__title',
    },
    text: {
      className: 'uninstall-extension__text',
    },
    restoreLink: {
      type: 'button' as const,
      className: 'uninstall-extension__link uninstall-extension__link--blue',
      onClick: () => {
        window.open(
          'https://chromewebstore.google.com/detail/substrata-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
          '_blank'
        );
      },
    },
    contactLink: {
      type: 'button' as const,
      className: 'uninstall-extension__link',
      onClick: () => {
        window.open('https://www.substrata.me/company/contact/', '_blank');
      },
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.content}>
        <LogoIcon {...attrs.logo} />
        <div {...attrs.title}>Chrome Extension Uninstalled</div>
        <div {...attrs.text}>
          <span>Didn’t mean to uninstall?</span>
          <button {...attrs.restoreLink}>
            <span>Click here to restore</span>
            <LinkIcon />
          </button>
        </div>
        <div {...attrs.text}>
          <span>Questions?</span>
          <button {...attrs.contactLink}>
            <span>Please contact is here</span>
            <LinkIcon />
          </button>
        </div>
        <Background />
      </div>
    </div>
  );
};

export default withPageTransition(UninstallExtension);
