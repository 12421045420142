import React from 'react';

const CheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4716 4.86239C12.7319 5.12282 12.7317 5.54493 12.4713 5.8052L7.13484 11.1385C7.00975 11.2635 6.84012 11.3337 6.66328 11.3337C6.48643 11.3336 6.31686 11.2632 6.19189 11.1381L3.52836 8.47145C3.26816 8.21095 3.26841 7.78884 3.52891 7.52864C3.78942 7.26845 4.21153 7.2687 4.47172 7.5292L6.66399 9.72404L11.5288 4.86212C11.7892 4.60184 12.2113 4.60197 12.4716 4.86239Z"
      fill="white"
    />
  </svg>
);

export default CheckedIcon;
