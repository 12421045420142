import React from 'react';

const LinkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.66634 3.33301C2.66634 2.96482 2.96482 2.66634 3.33301 2.66634H7.99967C8.36786 2.66634 8.66634 2.36786 8.66634 1.99967C8.66634 1.63148 8.36786 1.33301 7.99967 1.33301H3.33301C2.22844 1.33301 1.33301 2.22844 1.33301 3.33301V12.6663C1.33301 13.7709 2.22844 14.6663 3.33301 14.6663H12.6663C13.7709 14.6663 14.6663 13.7709 14.6663 12.6663V7.99967C14.6663 7.63148 14.3679 7.33301 13.9997 7.33301C13.6315 7.33301 13.333 7.63148 13.333 7.99967V12.6663C13.333 13.0345 13.0345 13.333 12.6663 13.333H3.33301C2.96482 13.333 2.66634 13.0345 2.66634 12.6663V3.33301Z"
      fill="#070B12"
    />
    <path
      d="M9.99967 1.99967C9.99967 1.63148 10.2982 1.33301 10.6663 1.33301H13.333C14.0694 1.33301 14.6663 1.92996 14.6663 2.66634V5.33301C14.6663 5.7012 14.3679 5.99967 13.9997 5.99967C13.6315 5.99967 13.333 5.7012 13.333 5.33301V3.60915L8.47108 8.47108C8.21073 8.73143 7.78862 8.73143 7.52827 8.47108C7.26792 8.21073 7.26792 7.78862 7.52827 7.52827L12.3902 2.66634H10.6663C10.2982 2.66634 9.99967 2.36786 9.99967 1.99967Z"
      fill="#070B12"
    />
  </svg>
);

export default LinkIcon;
