import React from 'react';

const ChromeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.99999 11.998C10.2091 11.998 12 10.2072 12 7.99804C12 5.7889 10.2091 3.99805 7.99999 3.99805C5.79086 3.99805 4 5.7889 4 7.99804C4 10.2072 5.79086 11.998 7.99999 11.998Z"
      fill="white"
    />
    <path
      d="M7.99982 4.00001H14.9269C14.2249 2.7838 13.2152 1.77384 11.9991 1.07168C10.783 0.369517 9.40352 -9.79566e-05 7.99929 1.94732e-08C6.59506 9.79955e-05 5.2156 0.369906 3.99963 1.07224C2.78366 1.77457 1.77403 2.78467 1.07227 4.00097L4.53579 9.99999L4.53889 9.99919C4.18656 9.39162 4.00063 8.70189 3.99984 7.99955C3.99905 7.29721 4.18343 6.60707 4.53439 5.9987C4.88536 5.39034 5.39051 4.88525 5.99891 4.53435C6.60731 4.18345 7.29747 3.99914 7.99982 4.00001Z"
      fill="url(#paint0_linear_1349_12234)"
    />
    <path
      d="M7.99967 11.1663C9.74856 11.1663 11.1663 9.74856 11.1663 7.99967C11.1663 6.25077 9.74856 4.83301 7.99967 4.83301C6.25077 4.83301 4.83301 6.25077 4.83301 7.99967C4.83301 9.74856 6.25077 11.1663 7.99967 11.1663Z"
      fill="#1A73E8"
    />
    <path
      d="M11.464 10.0009L8.00046 15.9999C9.40468 16.0001 10.7842 15.6306 12.0003 14.9285C13.2164 14.2264 14.2263 13.2166 14.9283 12.0004C15.6303 10.7843 15.9998 9.40473 15.9995 8.0005C15.9993 6.59628 15.6293 5.21687 14.9269 4.00098H7.99986L7.99902 4.00408C8.70137 4.00272 9.39166 4.18655 10.0003 4.53704C10.6089 4.88752 11.1144 5.39227 11.4658 6.0004C11.8172 6.60853 12.002 7.29855 12.0017 8.00089C12.0014 8.70324 11.8159 9.39309 11.464 10.0009Z"
      fill="url(#paint1_linear_1349_12234)"
    />
    <path
      d="M4.53661 10.001L1.07308 4.00195C0.37079 5.21795 0.0010289 6.59742 0.000976568 8.00165C0.000924237 9.40588 0.370583 10.7854 1.07278 12.0014C1.77498 13.2175 2.78497 14.2272 4.00119 14.9291C5.21742 15.631 6.597 16.0003 8.00123 15.9999L11.4648 10.0009L11.4625 9.99864C11.1125 10.6076 10.6082 11.1134 10.0003 11.4653C9.39245 11.8172 8.70258 12.0025 8.00024 12.0028C7.2979 12.003 6.6079 11.8181 5.99981 11.4666C5.39172 11.1152 4.88703 10.6097 4.53661 10.001Z"
      fill="url(#paint2_linear_1349_12234)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1349_12234"
        x1="1.07227"
        y1="5"
        x2="14.9269"
        y2="5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D93025" />
        <stop offset="1" stopColor="#EA4335" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1349_12234"
        x1="6.90753"
        y1="15.8929"
        x2="13.8348"
        y2="3.89451"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCC934" />
        <stop offset="1" stopColor="#FBBC04" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1349_12234"
        x1="8.86643"
        y1="15.5004"
        x2="1.93911"
        y2="3.50195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1E8E3E" />
        <stop offset="1" stopColor="#34A853" />
      </linearGradient>
    </defs>
  </svg>
);

export default ChromeIcon;
