import React from 'react';
import Slider from 'react-slick';

const LayoutFooter: React.FC = () => {
  const attrs = {
    container: {
      className: 'layout-footer',
    },
    disclaimer: {
      className: 'layout-footer__disclaimer',
    },
    slider: {
      className: 'layout-footer__slider',
      centerMode: false,
      dots: false,
      arrows: false,
      centerPadding: '10px',
      speed: 9000,
      slidesToShow: 22,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            arrows: false,
            slidesToShow: 12,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            arrows: false,
            slidesToShow: 10,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 8,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            arrows: false,
            centerPadding: '0',
            slidesToShow: 4,
            autoplay: true,
          },
        },
      ],
    },
  };

  const renderSlides = () =>
    Array.from({ length: 15 }).map((_, index) => {
      const props = {
        container: {
          key: index,
        },
        image: {
          src: `/assets/badge${index + 1}.svg`,
        },
      };

      return (
        <div {...props.container}>
          <img {...props.image} alt="" />
        </div>
      );
    });

  return (
    <div {...attrs.container}>
      <div {...attrs.disclaimer}>
        The #1 AI Sales Assistant | Sales Coaching | Conversation Intelligence
        Platform
      </div>
      <Slider {...attrs.slider}>
        {renderSlides()}
        {/* Duplicate slides to avoid issue with empty space */}
        {renderSlides()}
      </Slider>
    </div>
  );
};

export default LayoutFooter;
