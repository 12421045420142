import React from 'react';

const CrossIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_340_34353)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.364 5.63642C18.7545 6.02695 18.7545 6.66011 18.364 7.05064L13.4142 12.0004L18.364 16.9501C18.7545 17.3407 18.7545 17.9738 18.364 18.3643C17.9734 18.7549 17.3403 18.7549 16.9497 18.3643L12 13.4146L7.05025 18.3643C6.65973 18.7549 6.02656 18.7549 5.63604 18.3643C5.24552 17.9738 5.24552 17.3407 5.63604 16.9501L10.5858 12.0004L5.63604 7.05064C5.24551 6.66011 5.24551 6.02695 5.63604 5.63642C6.02656 5.2459 6.65973 5.2459 7.05025 5.63642L12 10.5862L16.9497 5.63642C17.3403 5.2459 17.9734 5.2459 18.364 5.63642Z"
        fill="#95A1B6"
      />
    </g>
    <defs>
      <clipPath id="clip0_340_34353">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CrossIcon;
