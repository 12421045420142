import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import { ROUTES } from '../../routes';
import {
  STRIPE_PROFFESIONAL_ANNUAL_PLAN,
  STRIPE_PROFFESIONAL_MONTHLY_PLAN,
  STRIPE_PROMISE_ID,
} from '../../constants/config';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Background, Select } from '../../components';
import { updateSelectedPlan } from '../../store/auth/auth.slice';
import { StripeIcon } from './icons';
import { CheckoutForm } from './components';
import { withPageTransition } from '../../hoc';

const stripePromise = loadStripe(STRIPE_PROMISE_ID);

const PaymentDetails: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const selectedPlan = useAppSelector((state) => state.auth.selectedPlan);
  const trialUsedStatus = useAppSelector((state) => state.auth.trialUsedStatus);

  const history = useHistory();

  const { trackEvent, isMixPanelInitialized } = useMixPanel();

  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  const planName = selectedPlan?.name || 'Professional';
  const usersCount = selectedPlan?.usersCount || 1;
  const planPrice = selectedPlan
    ? selectedPlan.stripePlanInfo.price - selectedPlan.stripePlanInfo.discount
    : 0;
  const totalPrice = planPrice * usersCount;
  const isAnnual = selectedPlan?.isAnnual || false;

  const attrs = {
    container: {
      className: 'payment-details',
    },
    content: {
      className: 'payment-details__content',
    },
    contentSection: {
      className: 'payment-details__content-section',
    },
    title: {
      className: 'payment-details__title',
    },
    trialDisclaimer: {
      className: 'payment-details__trial-disclaimer',
    },
    subscriptionInfo: {
      className: 'payment-details__subscription-info',
    },
    subscriptionInfoItem: {
      className: 'payment-details__subscription-info-item',
    },
    subscriptionCycleSelect: {
      width: 90,
      className: 'payment-details__subscription-cycle-select',
      value: isAnnual ? 'annual' : 'month',
      options: [
        {
          value: 'annual',
          label: 'Annually',
        },
        {
          value: 'month',
          label: 'Monthly',
        },
      ],
      onChange: (value: string) => {
        const isAnnualSelected = value === 'annual';

        dispatch(
          updateSelectedPlan(
            selectedPlan
              ? {
                  ...selectedPlan,
                  isAnnual: isAnnualSelected,
                  stripePlanInfo: isAnnualSelected
                    ? STRIPE_PROFFESIONAL_ANNUAL_PLAN
                    : STRIPE_PROFFESIONAL_MONTHLY_PLAN,
                }
              : null
          )
        );
      },
    },
    usersCountSelect: {
      width: 50,
      className: 'payment-details__users-count-select',
      value: `${usersCount}`,
      options: Array.from({ length: 10 }).map((_, index) => ({
        value: `${index + 1}`,
        label: `${index + 1}`,
      })),
      onChange: (value: string) => {
        dispatch(
          updateSelectedPlan(
            selectedPlan
              ? {
                  ...selectedPlan,
                  usersCount: +value,
                }
              : null
          )
        );
      },
    },
    subscriptionPrice: {
      className: 'payment-details__subscription-price',
    },
    subscriptionPriceDivider: {
      className: 'payment-details__subscription-price-divider',
    },
    subscriptionPriceRow: {
      className: 'payment-details__subscription-price-row',
    },
    subscriptionPriceRowDiscount: {
      className:
        'payment-details__subscription-price-row payment-details__subscription-price-row--discount',
    },
    subscriptionPriceRowTotal: {
      className:
        'payment-details__subscription-price-row payment-details__subscription-price-row--total',
    },
    footer: {
      className: 'payment-details__footer',
    },
    stripeLabelWrapper: {
      className: 'payment-details__stripe-label-wrapper',
    },
    linksWrapper: {
      className: 'payment-details__links-wrapper',
    },
    termsLink: {
      className: 'payment-details__link',
      rel: 'noopener noreferrer',
      target: '_blank',
      href: 'https://www.substrata.me/terms-of-service/',
    },
    privacyLink: {
      className: 'payment-details__link',
      rel: 'noopener noreferrer',
      target: '_blank',
      href: 'https://www.substrata.me/privacy-policy/',
    },
    stripeElements: {
      stripe: stripePromise,
      options: {
        mode: 'subscription' as const,
        amount: planPrice * 100,
        currency: 'usd',
        setup_future_usage: 'off_session' as const,
        paymentMethodTypes: ['card'],
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
          },
        ],
        appearance: {
          theme: 'flat' as const,
          variables: {
            colorBackground: '#FFFFFF',
            borderRadius: '4px',
            fontFamily: 'Poppins, sans-serif',
            colorPrimary: '#537BFF',
            colorText: '#070B12',
            colorDanger: '#F0384A',
          },
          rules: {
            '.Input': {
              border: '1px solid #E3E7ED',
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: '500',
              padding: '8px',
            },
            '.Input:focus': {
              borderColor: '#537BFF',
              outline: 'none',
              boxShadow: 'none',
            },
            '.Input--invalid': {
              borderColor: '#F0384A',
              boxShadow: 'none',
            },
            '.Input--invalid:focus': {
              borderColor: '#F0384A',
            },
            '.Input::placeholder': {
              color: '#95A1B6',
            },
            '.Label': {
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '24px',
              marginBottom: '8px',
            },
            '.Label--invalid': {
              color: '#F0384A',
            },
            '.Error': {
              fontSize: '12px',
              lineHeight: '16px',
              marginTop: '8px',
              fontWeight: '500',
            },
          },
        },
      },
    },
    checkoutForm: {
      totalPrice,
    },
  };

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.payment-details');
    }
  }, [isMixPanelInitialized, trackEvent]);

  useEffect(() => {
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.payment-details');
    }
  }, [isGoogleAnalyticsInitialized, trackGAEvent]);

  useEffect(() => {
    if (!selectedPlan || !user) {
      history.push(ROUTES.PRICING);
    }
  }, [history, selectedPlan, user]);

  return (
    <div {...attrs.container}>
      <div {...attrs.content}>
        <div {...attrs.contentSection}>
          <div {...attrs.title}>
            <span>{planName}</span>
            {trialUsedStatus ? null : (
              <span {...attrs.trialDisclaimer}>30 Day Trial</span>
            )}
          </div>
          <div {...attrs.subscriptionInfo}>
            <div {...attrs.subscriptionInfoItem}>
              <span>Account email:</span>
              <span>{user?.email || 'N/A'}</span>
            </div>
            <div {...attrs.subscriptionInfoItem}>
              <span>Subscription cycle</span>
              <Select {...attrs.subscriptionCycleSelect} />
            </div>
            <div {...attrs.subscriptionInfoItem}>
              <span>Users</span>
              <Select {...attrs.usersCountSelect} />
            </div>
          </div>
          <div {...attrs.subscriptionPrice}>
            <div {...attrs.subscriptionPriceDivider} />
            <div {...attrs.subscriptionPriceRow}>
              <span>${STRIPE_PROFFESIONAL_MONTHLY_PLAN.price}</span>
              <span>x</span>
              <span>
                {usersCount} user{usersCount === 1 ? '' : 's'}
              </span>
              <span>x</span>
              <span>{isAnnual ? '12 months' : '1 month'}</span>
              <span>
                $
                {STRIPE_PROFFESIONAL_MONTHLY_PLAN.price *
                  usersCount *
                  (isAnnual ? 12 : 1)}
              </span>
            </div>
            {isAnnual && selectedPlan?.stripePlanInfo.discount ? (
              <div {...attrs.subscriptionPriceRowDiscount}>
                <span>Annual Plan (15% Off)</span>
                <span>
                  -${selectedPlan.stripePlanInfo.discount * usersCount}
                </span>
              </div>
            ) : null}
            <div {...attrs.subscriptionPriceDivider} />
            <div {...attrs.subscriptionPriceRowTotal}>
              <span>Total</span>
              <span>${totalPrice}</span>
            </div>
          </div>
          <div {...attrs.footer}>
            <div {...attrs.stripeLabelWrapper}>
              <span>Powered by</span>
              <StripeIcon />
            </div>
            <span>|</span>
            <div {...attrs.linksWrapper}>
              <a {...attrs.termsLink}>Terms</a>
              <a {...attrs.privacyLink}>Privacy</a>
            </div>
          </div>
        </div>
        <div {...attrs.contentSection}>
          <Elements {...attrs.stripeElements}>
            <CheckoutForm {...attrs.checkoutForm} />
          </Elements>
        </div>
        <Background />
      </div>
    </div>
  );
};

export default withPageTransition(PaymentDetails);
