import React from 'react';

const CheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4717 4.86239C12.732 5.12282 12.7319 5.54493 12.4714 5.8052L7.13496 11.1385C7.00987 11.2635 6.84024 11.3337 6.6634 11.3337C6.48655 11.3336 6.31699 11.2632 6.19201 11.1381L3.52848 8.47145C3.26828 8.21095 3.26853 7.78884 3.52904 7.52864C3.78954 7.26845 4.21165 7.2687 4.47184 7.5292L6.66411 9.72404L11.5289 4.86212C11.7893 4.60184 12.2114 4.60197 12.4717 4.86239Z"
      fill="#537BFF"
    />
  </svg>
);

export default CheckedIcon;
