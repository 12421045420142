import React from 'react';

const withPageTransition = <T,>(Component: React.FC<T>) => (
  props: T
): JSX.Element => {
  const attrs = {
    container: {
      className: 'page-transition',
    },
  };

  return (
    <div {...attrs.container}>
      <Component {...props} />
    </div>
  );
};

export default withPageTransition;
