import React from 'react';
import { ButtonSize } from '../Button';
import { ModalFooterProps } from '../Modal/components';
import { Modal } from '../Modal';

export interface ConfirmationModalProps {
  isOpened: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: React.ReactNode;
  description?: React.ReactNode;
  showHeader?: boolean;
  footerProps?: Omit<ModalFooterProps, 'onCancel'>;
  buttonProps?: {
    text: string;
    isCloseButton?: boolean;
    className?: string;
    onClick?: () => void;
  };
  className?: string;
  onAnimationEnd?: () => void;
  onClose: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpened,
  icon,
  title,
  description,
  showHeader = false,
  footerProps,
  buttonProps,
  className,
  children,
  onAnimationEnd,
  onClose,
}) => {
  const IconComponent = icon || null;

  const attrs = {
    modal: {
      isOpened,
      onClose,
      onAnimationEnd,
      footerProps: footerProps
        ? {
            ...footerProps,
            buttonsSize: ButtonSize.XS,
          }
        : undefined,
      className,
      closeOnClickOutside: true,
      width: 384,
      isConfirmation: true,
      ...(showHeader && {
        headerProps: {},
      }),
    },
    content: {
      className: 'confirmation-modal',
    },
    icon: {
      className: 'confirmation-modal__icon',
    },
    title: {
      className: 'confirmation-modal__title',
    },
    description: {
      className: 'confirmation-modal__description',
    },
    button: {
      type: 'button' as const,
      className: `confirmation-modal__button${
        buttonProps?.className ? ` ${buttonProps.className}` : ''
      }`,
      onClick: () => {
        const { isCloseButton, onClick } = buttonProps || {};

        if (isCloseButton) {
          onClose();
        }

        onClick?.();
      },
    },
  };

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.content}>
        {IconComponent && <IconComponent {...attrs.icon} />}
        {children}
        {title && <div {...attrs.title}>{title}</div>}
        {description && <div {...attrs.description}>{description}</div>}
        {buttonProps && <button {...attrs.button}>{buttonProps.text}</button>}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
