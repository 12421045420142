import React from 'react';

const GoogleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    {...props}
  >
    <path
      d="M8.66406 7.43652V10.6408H13.117C12.9215 11.6713 12.3347 12.5439 11.4546 13.1306L14.1399 15.2142C15.7045 13.77 16.6071 11.6488 16.6071 9.12902C16.6071 8.54233 16.5545 7.97814 16.4566 7.43661L8.66406 7.43652Z"
      fill="#4285F4"
    />
    <path
      d="M4.02674 10.5156L3.42111 10.9792L1.27734 12.6491C2.6388 15.3494 5.4292 17.2148 8.66357 17.2148C10.8975 17.2148 12.7704 16.4777 14.1394 15.214L11.4541 13.1305C10.717 13.6269 9.77676 13.9278 8.66357 13.9278C6.51233 13.9278 4.68457 12.4761 4.03012 10.5204L4.02674 10.5156Z"
      fill="#34A853"
    />
    <path
      d="M1.27814 5.23242C0.714031 6.34561 0.390625 7.60178 0.390625 8.94064C0.390625 10.2795 0.714031 11.5357 1.27814 12.6489C1.27814 12.6563 4.03118 10.5127 4.03118 10.5127C3.8657 10.0162 3.76789 9.48973 3.76789 8.94056C3.76789 8.39139 3.8657 7.86489 4.03118 7.36845L1.27814 5.23242Z"
      fill="#FBBC05"
    />
    <path
      d="M8.66373 3.96156C9.88229 3.96156 10.9654 4.38277 11.8304 5.19515L14.1998 2.82579C12.7631 1.48693 10.8978 0.666992 8.66373 0.666992C5.42936 0.666992 2.6388 2.52489 1.27734 5.23276L4.0303 7.36897C4.68467 5.41327 6.5125 3.96156 8.66373 3.96156Z"
      fill="#EA4335"
    />
  </svg>
);

export default GoogleIcon;
