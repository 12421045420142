import React from 'react';

const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M6 9C6.27614 9 6.5 8.77614 6.5 8.5V5C6.5 4.72386 6.27614 4.5 6 4.5C5.72386 4.5 5.5 4.72386 5.5 5V8.5C5.5 8.77614 5.72386 9 6 9Z"
      fill="#646F87"
    />
    <path
      d="M6 2.75C6.34518 2.75 6.625 3.02982 6.625 3.375C6.625 3.72018 6.34518 4 6 4C5.65482 4 5.375 3.72018 5.375 3.375C5.375 3.02982 5.65482 2.75 6 2.75Z"
      fill="#646F87"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6ZM6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6C10 8.20914 8.20914 10 6 10Z"
      fill="#646F87"
    />
  </svg>
);

export default InfoIcon;
