export interface FeatureListItem {
  isIncluded: boolean;
  isFunctionality: boolean;
  name: string;
  tooltip?: string;
}

export const FEATURES_LIST: FeatureListItem[] = [
  {
    isFunctionality: false,
    isIncluded: true,
    name: 'Q for Email',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Thread Analysis',
    tooltip:
      'Review email thread dynamics and detect subtle shifts in tone, power, and sentiment across deal-related interactions.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'X-Ray Mode',
    tooltip:
      'Gain an under-the-surface view of hidden social signals and subtext.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Response Simulator',
    tooltip:
      'Test how your responses may be perceived by the other party before sending them.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Upper-Hand Analysis',
    tooltip: 'Identify who holds a social power advantage at any given point.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Authority Zone',
    tooltip: 'Visualizes your perceived level of authority at any given point.',
  },
  {
    isFunctionality: false,
    isIncluded: true,
    name: 'Soundwave',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Turn-Talking Analysis',
    tooltip: 'Detect and understand socially meaningful turn-taking patterns.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Topic Breakdown',
    tooltip: 'Review conversation topics and how they evolve during meetings.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Signal Stream',
    tooltip:
      'Analyze deal-related nonverbal interaction cues during online meetings.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Onset Pause Patterns',
    tooltip:
      'Analyze deal-critical periods of silence and their impact on the interaction.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Speech Velocity Modulations',
    tooltip:
      'Analyze changes in speaking speed to gauge confidence, stress, or hesitation.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Summaries and Next Actions',
    tooltip: 'Receive meeting recaps and recommended follow-up actions.',
  },
  {
    isFunctionality: false,
    isIncluded: true,
    name: 'Temper',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Decision-Making Speed',
    tooltip: "Assess your prospect's decision making speed and mandate.",
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Risk Appetite Analysis',
    tooltip:
      "Evaluate your prospects' comfort level with risk, and their risk-taking tendencies.",
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'OCEAN Analysis',
    tooltip: 'Gain personality insights based on the OCEAN (Big Five) model.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'DISC Analysis',
    tooltip:
      'Profile participants using the DISC method (Dominance, Influence, Steadiness, Compliance).',
  },
  {
    isFunctionality: false,
    isIncluded: true,
    name: 'Realtime Signals',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Prospect-Side Email Tracking',
    tooltip: 'Monitor when your emails are being read or forwarded.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Changes in Prospect  Sentiment',
    tooltip:
      'Detect subtle shifts in emotional tone and sentiment type across interactions.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Charges in Participant Dynamics',
    tooltip:
      'Detect subtle power plays performed through changes in Points of Contact.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Open Pattern Analysis',
    tooltip:
      'Uncover email open frequencies and timing patterns that may be indicative of a prospect’s interest level.',
  },
  {
    isFunctionality: false,
    isIncluded: true,
    name: 'Activity & Analytics',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Upper-Hand Ratio',
    tooltip:
      'Event-centric view of the power balance between the dealmaker and the prospect.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Estimated Tendencies',
    tooltip:
      'Understand your own tendencies (authority, empathy, responsiveness).',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Talk-Time Ratio',
    tooltip:
      'The aggregate distribution of relative speaking time across your recorded online calls.',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Meeting-Count',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Response-Time Breakdown',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Sent vs Received Sentiment',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Word-Count Breakdown',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Most Active Accounts',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Authority Zone Position',
  },
  {
    isFunctionality: true,
    isIncluded: true,
    name: 'Team Analytics',
  },
  {
    isFunctionality: false,
    isIncluded: true,
    name: 'Webhooks',
  },
  {
    isFunctionality: false,
    isIncluded: false,
    name: 'CRM Integration',
  },
  {
    isFunctionality: false,
    isIncluded: false,
    name: 'Workflow Integrations',
  },
  {
    isFunctionality: false,
    isIncluded: false,
    name: 'Custom Onboarding & Training',
  },
  {
    isFunctionality: false,
    isIncluded: false,
    name: 'GTM Success Manager',
  },
];
