import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { EXTENSION_ID } from '../../constants/config';
import { ROUTES } from '../../routes';
import { useFirebase } from '../../context';
import {
  ChromeMessage,
  ChromeMessageType,
} from '../../types/chromeMessage.types';

const Logout: React.FC = () => {
  const { isLoggedIn, isInitialized, signOut } = useFirebase();

  const history = useHistory();
  const { search } = useLocation();

  const redirectUrl = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    const returnUrl = encodeURIComponent(queryParams.get('returnUrl') || '');
    const client = queryParams.get('client');

    return `${ROUTES.HOME}${returnUrl ? `?returnUrl=${returnUrl}` : ''}${
      client ? `${returnUrl ? '&' : '?'}client=${client}` : ''
    }`;
  }, [search]);

  // Notify extension with logout message
  useEffect(() => {
    if (window.chrome?.runtime) {
      const message: ChromeMessage<ChromeMessageType.LOGOUT> = {
        type: ChromeMessageType.LOGOUT,
        payload: null,
      };

      window.chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
        if (response) {
          // eslint-disable-next-line no-console
          console.log(response);
        } else {
          // eslint-disable-next-line no-console
          console.error(window.chrome.runtime.lastError);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      signOut();
    }
  }, [isLoggedIn, signOut]);

  useEffect(() => {
    if (isInitialized && !isLoggedIn) {
      history.replace(redirectUrl);
    }
  }, [history, isInitialized, isLoggedIn, redirectUrl]);

  return null;
};

export default Logout;
