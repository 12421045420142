import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { checkPaymentStatus, getAuthCodes } from '../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ErrorIcon, SpinnerIcon } from './icons';
import {
  Background,
  Button,
  ButtonSize,
  ButtonVariant,
} from '../../components';
import { ACCOUNT_URL } from '../../constants/config';
import { ClientType } from '../../store/auth/auth.types';
import { withPageTransition } from '../../hoc';

const Processing: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const paymentStatus = useAppSelector((state) => state.auth.paymentStatus);
  const dashboardAuthCode = useAppSelector(
    (state) => state.auth.dashboardAuthCode
  );

  const isPaymentStatusError = useAppSelector(
    (state) => state.auth.isError.paymentStatus
  );

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { trackEvent, isMixPanelInitialized } = useMixPanel();
  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.processing-page');
    }
  }, [isMixPanelInitialized, trackEvent]);

  useEffect(() => {
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.processing-page');
    }
  }, [isGoogleAnalyticsInitialized, trackGAEvent]);

  useEffect(() => {
    if (user) {
      dispatch(checkPaymentStatus());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (paymentStatus === 'complete') {
      dispatch(getAuthCodes({ client: ClientType.DASHBOARD }));
    }
  }, [dispatch, paymentStatus]);

  useEffect(() => {
    if (dashboardAuthCode) {
      window.location.href = `${ACCOUNT_URL}?authCode=${dashboardAuthCode}`;
    }
  }, [dashboardAuthCode]);

  const attrs = {
    container: {
      className: 'processing',
    },
    content: {
      className: 'processing__content',
    },
    title: {
      className: 'processing__title',
    },
    icon: {
      className: 'processing__icon',
    },
    iconAnimated: {
      className: 'processing__icon processing__icon--animated',
    },
    paymentPageButton: {
      className: 'processing__button',
      size: ButtonSize.XS,
      variant: ButtonVariant.Danger,
      onClick: () => {
        history.push(ROUTES.PRICING);
      },
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.content}>
        {isPaymentStatusError ? (
          <>
            <ErrorIcon {...attrs.icon} />
            <span {...attrs.title}>Payment Status Unavailable</span>
            <span>
              We encountered an issue while processing your payment and couldn’t
              confirm the transaction status. If you believe the payment was
              successful, please check your email for a confirmation receipt and
              contact our support team for assistance. Otherwise, you can try
              again by clicking the button below.
            </span>
            <Button {...attrs.paymentPageButton}>
              <span>Retry Payment</span>
            </Button>
          </>
        ) : (
          <>
            <SpinnerIcon {...attrs.iconAnimated} />
            <span {...attrs.title}>Processing...</span>
          </>
        )}
        <Background />
      </div>
    </div>
  );
};

export default withPageTransition(Processing);
