import React from 'react';
import { CrossIcon } from './icons';

export interface ModalHeaderProps {
  title?: string;
  onClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose }) => {
  const attrs = {
    container: {
      className: 'modal-header',
    },
    button: {
      'aria-label': 'Close',
      type: 'button' as const,
      className: 'modal-header__button',
      onClick: onClose,
    },
    buttonIcon: {
      className: 'modal-header__button-icon',
    },
  };

  return (
    <div {...attrs.container}>
      {title ? <span>{title}</span> : null}
      <button {...attrs.button}>
        <CrossIcon {...attrs.buttonIcon} />
      </button>
    </div>
  );
};

export default ModalHeader;
