import React from 'react';
import { TooltipPosition } from '../Tooltip';

const ArrowIcon: React.FC<
  React.SVGProps<SVGSVGElement> & {
    dark: boolean;
    position: TooltipPosition;
  }
> = ({ position, dark, ...rest }) => {
  const fill = dark ? '#18222F' : '#FFFFFF';
  const stroke = dark ? '#44536A' : '#E3E7ED';

  if (
    position === TooltipPosition.RIGHT ||
    position === TooltipPosition.RIGHT_START ||
    position === TooltipPosition.RIGHT_END
  ) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="22"
        viewBox="0 0 11 22"
        fill="none"
        {...rest}
      >
        <path
          d="M9.64645 2.64645L10.5 1.79289V3L10.5 19V20.2071L9.64645 19.3536L1.64645 11.3536L1.29289 11L1.64645 10.6464L9.64645 2.64645Z"
          stroke={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 20L2 11L11 2L11 20ZM10 4.5L10 17.5L3.5 11L10 4.5Z"
          fill={fill}
        />
        <path d="M10 17.5L10 4.5L3.5 11L10 17.5Z" fill={fill} />
      </svg>
    );
  }

  if (
    position === TooltipPosition.BOTTOM ||
    position === TooltipPosition.BOTTOM_START ||
    position === TooltipPosition.BOTTOM_END
  ) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="11"
        viewBox="0 0 22 11"
        fill="none"
        {...rest}
      >
        <path
          d="M19.3536 9.64645L20.2071 10.5H19L3 10.5H1.79289L2.64645 9.64645L10.6464 1.64645L11 1.29289L11.3536 1.64645L19.3536 9.64645Z"
          stroke={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 11L11 2L20 11L2 11ZM17.5 10L4.5 10L11 3.5L17.5 10Z"
          fill={fill}
        />
        <path d="M4.5 10L17.5 10L11 3.5L4.5 10Z" fill={fill} />
      </svg>
    );
  }

  if (
    position === TooltipPosition.LEFT ||
    position === TooltipPosition.LEFT_START ||
    position === TooltipPosition.LEFT_END
  ) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="22"
        viewBox="0 0 11 22"
        fill="none"
        {...rest}
      >
        <path
          d="M1.35355 2.64645L0.5 1.79289V3L0.5 19V20.2071L1.35355 19.3536L9.35355 11.3536L9.70711 11L9.35355 10.6464L1.35355 2.64645Z"
          stroke={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 20L9 11L0 2L0 20ZM1 4.5L1 17.5L7.5 11L1 4.5Z"
          fill={fill}
        />
        <path d="M1 17.5L1 4.5L7.5 11L1 17.5Z" fill={fill} />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="11"
      viewBox="0 0 22 11"
      fill="none"
      {...rest}
    >
      <path
        d="M19.3536 1.35355L20.2071 0.5H19L3 0.5H1.79289L2.64645 1.35355L10.6464 9.35355L11 9.70711L11.3536 9.35355L19.3536 1.35355Z"
        stroke={stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0L11 9L20 0L2 0ZM17.5 1L4.5 1L11 7.5L17.5 1Z"
        fill={fill}
      />
      <path d="M4.5 1L17.5 1L11 7.5L4.5 1Z" fill={fill} />
    </svg>
  );
};

export default ArrowIcon;
