import React from 'react';

const ErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M16.0006 8.00033C16.737 8.00033 17.334 8.59728 17.334 9.33366V18.667C17.334 19.4034 16.737 20.0003 16.0006 20.0003C15.2643 20.0003 14.6673 19.4034 14.6673 18.667V9.33366C14.6673 8.59728 15.2643 8.00033 16.0006 8.00033Z"
      fill="#F0384A"
    />
    <path
      d="M16.0006 24.667C16.9211 24.667 17.6673 23.9208 17.6673 23.0003C17.6673 22.0799 16.9211 21.3337 16.0006 21.3337C15.0802 21.3337 14.334 22.0799 14.334 23.0003C14.334 23.9208 15.0802 24.667 16.0006 24.667Z"
      fill="#F0384A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.138 6.37161C11.2135 4.10882 13.4953 2.66699 16.0006 2.66699C18.506 2.66699 20.7878 4.10882 21.8633 6.37161L29.1541 21.7108C30.8362 25.2497 28.2556 29.3337 24.3372 29.3337H7.66406C3.74571 29.3337 1.16507 25.2497 2.84715 21.7108L10.138 6.37161ZM16.0006 5.33366C14.5245 5.33366 13.1801 6.18317 12.5465 7.51637L5.2556 22.8556C4.41456 24.625 5.70488 26.667 7.66406 26.667H24.3372C26.2964 26.667 27.5867 24.625 26.7457 22.8556L19.4548 7.51637C18.8211 6.18316 17.4768 5.33366 16.0006 5.33366Z"
      fill="#F0384A"
    />
  </svg>
);

export default ErrorIcon;
