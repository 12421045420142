import axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from './constants/config';

export interface ExtendedAxiosRequestConfig<T = any>
  extends AxiosRequestConfig<T> {
  retry?: number;
  retryDelay?: number;
}

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
