export const MODE: string = process.env.REACT_APP_MODE || 'local';

let apiLink = '';

switch (MODE) {
  case 'prod':
    apiLink = 'https://beta-api.substrata.me';
    break;
  case 'stage':
    apiLink = 'https://staging.beta-api.substrata.me';
    break;
  default:
    apiLink = 'http://localhost:8002';
    break;
}

export const API_URL: string = process.env.REACT_APP_API_URL || apiLink;

export const EXTENSION_ID: string =
  process.env.REACT_APP_EXTENSION_ID || 'okeemaalpjcfeggfnahbeoagboahbbka';

export const MINIMUM_EXTENSION_VERSION: string =
  process.env.REACT_APP_MINIMUM_EXTENSION_VERSION || '7.4.3';

let accountUrl = '';

switch (MODE) {
  case 'prod':
    accountUrl = 'https://account.substrata.me';
    break;
  case 'stage':
    accountUrl = 'https://staging.account.substrata.me';
    break;
  default:
    accountUrl = 'http://localhost:3003';
    break;
}

export const ACCOUNT_URL: string =
  process.env.REACT_APP_ACCOUNT_URL || accountUrl;

let superAccountUrl = '';

switch (MODE) {
  case 'prod':
    superAccountUrl = 'https://admin.substrata.me';
    break;
  case 'stage':
    superAccountUrl = 'https://staging.admin.substrata.me';
    break;
  default:
    superAccountUrl = 'http://localhost:3004';
    break;
}

export const SUPER_ACCOUNT_URL: string =
  process.env.REACT_APP_SUPER_ACCOUNT_URL || superAccountUrl;

let signupUrl = '';

switch (process.env.REACT_APP_MODE) {
  case 'prod':
    signupUrl = 'https://signup.substrata.me';
    break;
  case 'stage':
    signupUrl = 'https://staging.signup.substrata.me';
    break;
  default:
    signupUrl = 'http://localhost:3002';
    break;
}

export const SIGNUP_URL: string = process.env.REACT_APP_SIGNUP_URL || signupUrl;

let mixPanelToken = '';

switch (MODE) {
  case 'prod':
    mixPanelToken = '5c1b399da91785f377e01401f7660db7';
    break;
  case 'stage':
    mixPanelToken = 'a10f3438f40978dcfdf3e97dbda8c55a';
    break;
  default:
    mixPanelToken = '512032c4a184cd53742c05bd87f99e8e';
    break;
}

export const MIX_PANEL_TOKEN: string = mixPanelToken;

let ga4MeasurementId = '';

switch (MODE) {
  case 'prod':
    ga4MeasurementId = 'G-LV0R3Z2N71';
    break;
  case 'stage':
    ga4MeasurementId = 'G-Y4SW101C8D';
    break;
  default:
    ga4MeasurementId = 'G-M6H20P64DT';
    break;
}

export const GA4_MEASUREMENT_ID: string = ga4MeasurementId;

let bluesnapSku = '';

switch (MODE) {
  case 'prod':
    bluesnapSku = '4068068';
    break;
  case 'stage':
    bluesnapSku = '2894667';
    break;
  default:
    bluesnapSku = '2881111';
    break;
}

export const BLUESNAP_SKU: string = bluesnapSku;

let bluesnapStoreId = '';

switch (MODE) {
  case 'prod':
    bluesnapStoreId = '639609';
    break;
  case 'stage':
    bluesnapStoreId = '52832';
    break;
  default:
    bluesnapStoreId = '52832';
    break;
}

export const BLUESNAP_STOREID: string = bluesnapStoreId;

let stripePromiseId = '';

switch (MODE) {
  case 'prod':
    stripePromiseId =
      'pk_live_51LSQqxK6D0U3ayUuTpAiBvR3xHfdU0VjF4gYk2eXU9w9d3L5YwaC062guRY4QEtSuAN8U8vu5US1pp8kQvQwbKmH00Up08pMQ3';
    break;
  case 'stage':
    stripePromiseId =
      'pk_test_51LSQqxK6D0U3ayUuF0JHHGtOFbwju7AVfaYhCeCe78EIlufTRiVQHywXQQkHpNurHOfYzOLqfpK1ERmpQQ9Tp5F900hKHU61UI';
    break;
  default:
    stripePromiseId = 'pk_test_FwlyhLFtZ671W5JBIXfVqpB400jJdn7L19';
    break;
}

export const STRIPE_PROMISE_ID: string = stripePromiseId;

export const STRIPE_PROFFESIONAL_MONTHLY_PLAN = {
  productId: '',
  priceId: '',
  price: 50,
  discount: 0,
};

switch (MODE) {
  case 'prod': {
    STRIPE_PROFFESIONAL_MONTHLY_PLAN.productId = 'prod_RkkqNzuXUXnhfm';
    STRIPE_PROFFESIONAL_MONTHLY_PLAN.priceId = 'price_1QrFKsK6D0U3ayUu1ytTBKuW';
    break;
  }
  case 'stage': {
    STRIPE_PROFFESIONAL_MONTHLY_PLAN.productId = 'prod_RlICBoObzEF4ct';
    STRIPE_PROFFESIONAL_MONTHLY_PLAN.priceId = 'price_1QvztoK6D0U3ayUuMhp7968g';
    break;
  }
  default: {
    STRIPE_PROFFESIONAL_MONTHLY_PLAN.productId = 'prod_RlICBoObzEF4ct';
    STRIPE_PROFFESIONAL_MONTHLY_PLAN.priceId = 'price_1QvztoK6D0U3ayUuMhp7968g';
    break;
  }
}

export const STRIPE_PROFFESIONAL_ANNUAL_PLAN = {
  productId: '',
  priceId: '',
  price: 600,
  discount: 90,
};

switch (MODE) {
  case 'prod': {
    STRIPE_PROFFESIONAL_ANNUAL_PLAN.productId = 'prod_RkkqNzuXUXnhfm';
    STRIPE_PROFFESIONAL_ANNUAL_PLAN.priceId = 'price_1QrFMFK6D0U3ayUu7FctUCAy';
    break;
  }
  case 'stage': {
    STRIPE_PROFFESIONAL_ANNUAL_PLAN.productId = 'prod_RlICBoObzEF4ct';
    STRIPE_PROFFESIONAL_ANNUAL_PLAN.priceId = 'price_1Qvzu3K6D0U3ayUunvFR1Kps';
    break;
  }
  default: {
    STRIPE_PROFFESIONAL_ANNUAL_PLAN.productId = 'prod_RlICBoObzEF4ct';
    STRIPE_PROFFESIONAL_ANNUAL_PLAN.priceId = 'price_1Qvzu3K6D0U3ayUunvFR1Kps';
    break;
  }
}

export const FIREBASE_CONFIG = {
  apiKey: '',
  authDomain: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: '',
  appId: '',
};

/**
 * Firebase-related APIs use API keys only to identify the Firebase project or app, not for authorization to call the API (like some other APIs allow).
 * https://firebase.google.com/docs/projects/api-keys
 */
switch (MODE) {
  case 'prod': {
    FIREBASE_CONFIG.apiKey = 'AIzaSyCQWpXzUZ5z_lQvzy7nVVDYh6n9a-Z9PRU';
    FIREBASE_CONFIG.authDomain = 'user-auth.substrata.me';
    FIREBASE_CONFIG.projectId = 'substrata-production';
    FIREBASE_CONFIG.storageBucket = 'substrata-production.firebasestorage.app';
    FIREBASE_CONFIG.messagingSenderId = '783358435666';
    FIREBASE_CONFIG.appId = '1:783358435666:web:ccde0bf1a16c2129fa5a8b';
    break;
  }
  case 'stage': {
    FIREBASE_CONFIG.apiKey = 'AIzaSyArGgBlAzgAFjp04kvdvH_WEeFGZhf5IMY';
    FIREBASE_CONFIG.authDomain = 'staging.user-auth.substrata.me';
    FIREBASE_CONFIG.projectId = 'substrata-ops';
    FIREBASE_CONFIG.storageBucket = 'substrata-ops.appspot.com';
    FIREBASE_CONFIG.messagingSenderId = '155879858431';
    FIREBASE_CONFIG.appId = '1:155879858431:web:ea7a839afd07c0bc786cca';
    break;
  }
  default: {
    FIREBASE_CONFIG.apiKey = 'AIzaSyDFnc6WtQraep8K2F0ux8nqyi4kOrMNrg8';
    FIREBASE_CONFIG.authDomain = 'substrata-cto.firebaseapp.com';
    FIREBASE_CONFIG.projectId = 'substrata-cto';
    FIREBASE_CONFIG.storageBucket = 'substrata-cto.appspot.com';
    FIREBASE_CONFIG.messagingSenderId = '993137959493';
    FIREBASE_CONFIG.appId = '1:993137959493:web:0b002592b7dc26f9f1792c';
    break;
  }
}

export const FIREBASE_APP_NAME = 'adminApp';
