import React from 'react';

const ArrowLeftIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99689 8.87797C4.55703 8.37527 4.55703 7.62466 4.99689 7.12196L9.27943 2.22765C9.52189 1.95056 9.94306 1.92249 10.2202 2.16494C10.4972 2.4074 10.5253 2.82857 10.2829 3.10566L6.00033 7.99996L10.2829 12.8943C10.5253 13.1714 10.4972 13.5926 10.2201 13.835C9.94306 14.0775 9.52188 14.0494 9.27943 13.7723L4.99689 8.87797Z"
      fill="#070B12"
    />
  </svg>
);

export default ArrowLeftIcon;
