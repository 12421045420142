import React from 'react';
import { BackgroundIcon } from './icons';

const Background: React.FC = () => {
  const attrs = {
    container: {
      className: 'background',
    },
    icon: {
      className: 'background__icon',
    },
  };
  return (
    <div {...attrs.container}>
      <BackgroundIcon {...attrs.icon} />
    </div>
  );
};

export default Background;
