import React from 'react';

export enum ButtonSize {
  XS = 'xs',
  S = 's',
  M = 'm',
}

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Transparent = 'transparent',
  Error = 'error',
  Success = 'success',
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<
    HTMLButtonElement | HTMLLabelElement | HTMLAnchorElement
  > {
  as?: 'button' | 'label' | 'a';
  variant?: ButtonVariant;
  size?: ButtonSize;
}

const Button: React.FC<ButtonProps> = ({
  as = 'button',
  children,
  className,
  variant,
  size = ButtonSize.M,
  type = 'button',
  ...rest
}) => {
  const Component = as;

  const attrs = {
    button: {
      className: `button${size ? ` button--${size}` : ''}${
        variant ? ` button--${variant}` : ''
      }${className ? ` ${className}` : ''}`,
      ...(as === 'button' && { type }),
      ...rest,
    },
  };

  return <Component {...attrs.button}>{children}</Component>;
};

export default Button;
