import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthState, SelectedPlan } from './auth.types';
import {
  checkPaymentStatus,
  getAuthCodes,
  getIdentity,
  getTrialUsedStatus,
  sendUninstallExtension,
  signOut,
  verifyInviteToken,
} from './auth.thunks';

const intialState: AuthState = {
  isLoading: {
    authCodes: false,
    signIn: false,
    signOut: false,
    identity: false,
    uninstallExtension: false,
    paymentStatus: false,
    trialUsedStatus: false,
    verifyInviteToken: false,
    paymentConfirmation: false,
  },
  isError: {
    authCodes: false,
    signIn: false,
    signOut: false,
    identity: false,
    uninstallExtension: false,
    paymentStatus: false,
    trialUsedStatus: false,
    verifyInviteToken: false,
  },
  isLoggedIn: false,
  isInitialized: false,
  dashboardAuthCode: null,
  superAdminAuthCode: null,
  extensionAuthCode: null,
  user: null,
  org: null,
  userSignUpFlow: null,
  paymentStatus: null,
  trialUsedStatus: false,
  selectedPlan: null,
  isInvitationErrorModalOpened: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: intialState,
  reducers: {
    initAuth: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = true;
      state.isLoggedIn = state.isLoggedIn || action.payload;
    },
    startSignIn: (state) => {
      state.isError.signIn = false;
      state.isLoading.signIn = true;
    },
    endSignIn: (state) => {
      state.isLoading.signIn = false;
    },
    throwSignInError: (state) => {
      state.isError.signIn = true;
    },
    clearSignInError: (state) => {
      state.isError.signIn = false;
      state.isError.identity = false;
    },
    updateIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    updateSelectedPlan: (state, action: PayloadAction<SelectedPlan | null>) => {
      state.selectedPlan = action.payload;
    },
    openInvitationErrorModal: (state) => {
      state.isInvitationErrorModalOpened = true;
    },
    closeInvitationErrorModal: (state) => {
      state.isInvitationErrorModalOpened = false;
    },
    updatePaymentConfirmationLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading.paymentConfirmation = action.payload;
    },
    resetAuthState: () => {
      return intialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAuthCodes.pending, (state) => {
      state.isLoading.authCodes = true;
      state.isError.authCodes = false;
    });
    builder.addCase(getAuthCodes.fulfilled, (state, action) => {
      state.isLoading.authCodes = false;
      state.dashboardAuthCode = action.payload.dashboardAuthCode;
      state.superAdminAuthCode = action.payload.superAdminAuthCode;
      state.extensionAuthCode = action.payload.extensionAuthCode;
    });
    builder.addCase(getAuthCodes.rejected, (state, action) => {
      state.isLoading.authCodes = false;
      state.isError.authCodes = !action.meta.aborted;
    });
    builder.addCase(signOut.pending, (state) => {
      state.isLoading.signOut = true;
      state.isError.signOut = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.isLoading.signOut = false;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.isLoading.signOut = false;
      state.isError.signOut = !action.meta.aborted;
    });
    builder.addCase(getIdentity.pending, (state) => {
      state.isLoading.identity = true;
      state.isError.identity = false;
    });
    builder.addCase(getIdentity.fulfilled, (state, action) => {
      state.isLoading.identity = false;
      state.user = action.payload.user;
      state.org = action.payload.org;
      state.userSignUpFlow = action.payload.userSignUpFlow;
    });
    builder.addCase(getIdentity.rejected, (state, action) => {
      state.isLoading.identity = false;
      state.isError.identity = !action.meta.aborted;
    });
    builder.addCase(sendUninstallExtension.pending, (state) => {
      state.isLoading.uninstallExtension = true;
      state.isError.uninstallExtension = false;
    });
    builder.addCase(sendUninstallExtension.fulfilled, (state) => {
      state.isLoading.uninstallExtension = false;
    });
    builder.addCase(sendUninstallExtension.rejected, (state, action) => {
      state.isLoading.uninstallExtension = false;
      state.isError.uninstallExtension = !action.meta.aborted;
    });
    builder.addCase(checkPaymentStatus.pending, (state) => {
      state.isLoading.paymentStatus = true;
      state.isError.paymentStatus = false;
    });
    builder.addCase(checkPaymentStatus.fulfilled, (state, action) => {
      state.isLoading.paymentStatus = false;
      state.paymentStatus = action.payload;
    });
    builder.addCase(checkPaymentStatus.rejected, (state, action) => {
      state.isLoading.paymentStatus = false;
      state.paymentStatus = action.payload ? action.payload : null;
      state.isError.paymentStatus = !action.meta.aborted;
    });
    builder.addCase(getTrialUsedStatus.pending, (state) => {
      state.isLoading.trialUsedStatus = true;
      state.isError.trialUsedStatus = false;
    });
    builder.addCase(getTrialUsedStatus.fulfilled, (state, action) => {
      state.isLoading.trialUsedStatus = false;
      state.trialUsedStatus = action.payload;
    });
    builder.addCase(getTrialUsedStatus.rejected, (state, action) => {
      state.isLoading.trialUsedStatus = false;
      state.isError.trialUsedStatus = !action.meta.aborted;
    });
    builder.addCase(verifyInviteToken.pending, (state) => {
      state.isLoading.verifyInviteToken = true;
      state.isError.verifyInviteToken = false;
    });
    builder.addCase(verifyInviteToken.fulfilled, (state) => {
      state.isLoading.verifyInviteToken = false;
    });
    builder.addCase(verifyInviteToken.rejected, (state, action) => {
      state.isLoading.verifyInviteToken = false;
      state.isError.verifyInviteToken = !action.meta.aborted;
    });
  },
});

export const {
  initAuth,
  startSignIn,
  endSignIn,
  throwSignInError,
  clearSignInError,
  updateIsLoggedIn,
  updateSelectedPlan,
  openInvitationErrorModal,
  closeInvitationErrorModal,
  updatePaymentConfirmationLoading,
  resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;
