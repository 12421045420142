import React from 'react';

const ArrowDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.12203 10.7844C7.62473 11.2242 8.37534 11.2242 8.87804 10.7844L13.7723 6.50182C14.0494 6.25936 14.0775 5.83819 13.8351 5.5611C13.5926 5.28401 13.1714 5.25593 12.8943 5.49839L8.00004 9.78092L3.10568 5.49838C2.82859 5.25593 2.40741 5.28401 2.16496 5.5611C1.9225 5.83819 1.95058 6.25937 2.22767 6.50182L7.12203 10.7844Z"
      fill="#646F87"
    />
  </svg>
);

export default ArrowDownIcon;
