import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { ExtendedAxiosRequestConfig } from '../../axios';
import {
  ClientType,
  GetAuthCodeResponse,
  Organization,
  PaymentStatus,
  User,
  UserSignUpFlow,
} from './auth.types';
import { ApiResponseData } from '../../types';
import { RootState } from '../store';

export const getAuthCodes = createAsyncThunk<
  {
    dashboardAuthCode: string | null;
    superAdminAuthCode: string | null;
    extensionAuthCode: string;
  },
  {
    client: ClientType | null;
  }
>(
  'auth/getAuthCodes',
  async (
    { client: clientValue },
    { fulfillWithValue, rejectWithValue, signal }
  ) => {
    try {
      const client =
        clientValue === ClientType.DASHBOARD ||
        clientValue === ClientType.SUPER_ADMIN
          ? clientValue
          : ClientType.DASHBOARD;

      const response = await Promise.all([
        axios.get<ApiResponseData<GetAuthCodeResponse>>(`/auth-code/get`, {
          params: {
            client,
          },
          signal,
        }),
        axios.get<ApiResponseData<GetAuthCodeResponse>>(`/auth-code/get`, {
          params: {
            client: ClientType.EXTENSION,
          },
          signal,
        }),
      ]);

      const [clientResponse, extensionResponse] = response;

      return fulfillWithValue({
        dashboardAuthCode:
          client === ClientType.DASHBOARD
            ? clientResponse.data.payload.authCode
            : null,
        superAdminAuthCode:
          client === ClientType.SUPER_ADMIN
            ? clientResponse.data.payload.authCode
            : null,
        extensionAuthCode: extensionResponse.data.payload.authCode,
      });
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const getIdentity = createAsyncThunk<{
  user: User;
  org: Organization;
  userSignUpFlow: UserSignUpFlow;
}>(
  'auth/getIdentity',
  async (_, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const response = await axios.get<
        ApiResponseData<{
          user: User;
          org: Organization;
          state: UserSignUpFlow;
        }>
      >(`/api/v1/identity`, {
        signal,
      });

      const { user, org, state } = response.data.payload;

      return fulfillWithValue({ user, org, userSignUpFlow: state });
    } catch (error) {
      return rejectWithValue(null);
    }
  }
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      await axios.get('/logout', { signal });
      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);

export const sendUninstallExtension = createAsyncThunk<
  null,
  {
    extensionVersion: string;
  }
>(
  'auth/sendUninstallExtension',
  async (
    { extensionVersion },
    { getState, fulfillWithValue, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      await axios.post(
        `/services/orgs/${user?.orgId}/users/${user?.userId}/apps/chrome-extension/stats/installHistory`,
        {
          action: 'removing',
          date: new Date().toISOString(),
          version: extensionVersion,
        },
        {
          signal,
        }
      );

      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const checkPaymentStatus = createAsyncThunk<
  PaymentStatus,
  void,
  {
    rejectValue: PaymentStatus;
  }
>(
  'auth/checkPaymentStatus',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      await axios.get(
        `/services/billing/stripe/orgs/${user?.orgId}/users/${user?.userId}/status`,
        { signal, retry: 3, retryDelay: 5000 } as ExtendedAxiosRequestConfig
      );

      return fulfillWithValue(PaymentStatus.COMPLETE);
    } catch (e) {
      return rejectWithValue(PaymentStatus.INCOMPLETE);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const getTrialUsedStatus = createAsyncThunk<boolean>(
  'auth/getTrialUsedStatus',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const response = await axios.get<ApiResponseData<{ payload: boolean }>>(
        `/services/billing/orgs/${user?.orgId}/trial`,
        { signal }
      );

      return fulfillWithValue(response.data.payload.payload);
    } catch (e) {
      return rejectWithValue(false);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const verifyInviteToken = createAsyncThunk<
  null,
  { token: string; orgId: string }
>(
  'auth/verifyInviteToken',
  async ({ token, orgId }, { fulfillWithValue, rejectWithValue, signal }) => {
    try {
      await axios.get(`/orgs/${orgId}/signup/${token}`, { signal });

      return fulfillWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  }
);
