import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ROUTES } from './routes';
import { SignupRouter } from './SignupRouter';
import { FirebaseProvider } from './context';
import { Logout, SignupRedirect, UninstallExtension } from './pages';
import {
  ClientInterceptors,
  GoogleAnalytics,
  Layout,
  MixPanel,
} from './components';
import './App.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App: React.FC = () => {
  return (
    <Router>
      <Provider store={store}>
        <FirebaseProvider>
          <ClientInterceptors />
          <Layout>
            <Switch>
              <Route path={ROUTES.LOGOUT}>
                <Logout />
              </Route>
              <Route path={ROUTES.Q_UNINSTALL}>
                <UninstallExtension />
              </Route>
              <Route
                path={[
                  ROUTES.HOME,
                  ROUTES.PRICING,
                  ROUTES.PAYMENT_DETAILS,
                  ROUTES.PROCESSING,
                  ROUTES.ADD_EXTENSION,
                  ROUTES.INVITE,
                ]}
                exact
              >
                <SignupRouter />
              </Route>
              <Route path={[ROUTES.LOGIN, ROUTES.SIGNUP]}>
                <SignupRedirect />
              </Route>
              <Route path="*">
                <Redirect to={ROUTES.HOME} />
              </Route>
            </Switch>
          </Layout>
          <MixPanel />
          <GoogleAnalytics />
        </FirebaseProvider>
      </Provider>
    </Router>
  );
};

export default App;
