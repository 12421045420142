import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  ConfirmationModal,
} from '../../components';
import { GoogleIcon } from './icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useFirebase } from '../../context';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { ClientType, UserSignUpFlow } from '../../store/auth/auth.types';
import { getAuthCodes, verifyInviteToken } from '../../store/auth/auth.thunks';
import { ROUTES } from '../../routes';
import { ACCOUNT_URL, SUPER_ACCOUNT_URL } from '../../constants/config';
import { WarningIcon } from '../../icons';
import {
  clearSignInError,
  closeInvitationErrorModal,
  openInvitationErrorModal,
  resetAuthState,
} from '../../store/auth/auth.slice';
import { withPageTransition } from '../../hoc';

const Signup: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const userSignUpFlow = useAppSelector((state) => state.auth.userSignUpFlow);

  const dashboardAuthCode = useAppSelector(
    (state) => state.auth.dashboardAuthCode
  );

  const superAdminAuthCode = useAppSelector(
    (state) => state.auth.superAdminAuthCode
  );

  const extensionAuthCode = useAppSelector(
    (state) => state.auth.extensionAuthCode
  );

  const isSignInError = useAppSelector((state) => state.auth.isError.signIn);

  const isIdentityError = useAppSelector(
    (state) => state.auth.isError.identity
  );

  const isInvitationErrorModalOpened = useAppSelector(
    (state) => state.auth.isInvitationErrorModalOpened
  );

  const {
    isLoggedIn,
    isInitialized,
    isSignInLoading,
    googleSignIn,
    signOut,
  } = useFirebase();

  const history = useHistory();
  const { search } = useLocation();
  const { orgId, token } = useParams<{ orgId: string; token: string }>();

  const { trackEvent } = useMixPanel();
  const { trackGAEvent } = useGoogleAnalytics();

  const { returnUrl, client } = useMemo(() => {
    const queryParams = new URLSearchParams(search);

    return {
      returnUrl: decodeURIComponent(queryParams.get('returnUrl') || ''),
      client: queryParams.get('client') as ClientType,
    };
  }, [search]);

  const emailFromToken = useMemo(() => {
    if (token) {
      const { email } = jwtDecode<{ email: string }>(token);
      return email;
    }

    return null;
  }, [token]);

  // Get auth codes for apps
  useEffect(() => {
    if (emailFromToken && user && user.email !== emailFromToken) {
      return;
    }

    if (
      isLoggedIn &&
      userSignUpFlow &&
      userSignUpFlow === UserSignUpFlow.COMPLETED
    ) {
      dispatch(getAuthCodes({ client }));
    }
  }, [client, dispatch, emailFromToken, isLoggedIn, user, userSignUpFlow]);

  // Verify invite token
  useEffect(() => {
    if (orgId && token) {
      dispatch(verifyInviteToken({ orgId, token }));
    }
  }, [orgId, token, dispatch]);

  // Handle redirects
  useEffect(() => {
    if (!user) {
      return;
    }

    if (emailFromToken && user.email !== emailFromToken) {
      signOut();
      dispatch(resetAuthState());
      dispatch(openInvitationErrorModal());
      return;
    }

    if (userSignUpFlow && userSignUpFlow !== UserSignUpFlow.COMPLETED) {
      history.push(ROUTES.PRICING);
      return;
    }

    if (superAdminAuthCode && (!returnUrl || returnUrl === SUPER_ACCOUNT_URL)) {
      window.location.href = `${SUPER_ACCOUNT_URL}?authCode=${superAdminAuthCode}`;
      return;
    }

    if (extensionAuthCode && returnUrl?.includes('mail.google.com')) {
      window.location.href = `https://mail.google.com/mail/u/${user.email}/#inbox`;
      return;
    }

    if (dashboardAuthCode) {
      window.location.href = `${ACCOUNT_URL}?authCode=${dashboardAuthCode}`;
    }
  }, [
    dashboardAuthCode,
    dispatch,
    emailFromToken,
    extensionAuthCode,
    history,
    returnUrl,
    signOut,
    superAdminAuthCode,
    user,
    userSignUpFlow,
  ]);

  const onGoogleSignIn = () => {
    if (emailFromToken) {
      googleSignIn(emailFromToken);
    } else {
      googleSignIn();
    }
  };

  const renderErrorModalDescription = () => {
    const props = {
      supportLink: {
        className: 'signup__email-link',
        href: 'mailto:support@substrata.me',
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    };

    return (
      <>
        This email address is either not associated with a user or it has not
        beed activated. To get started, please reach out to{' '}
        <a {...props.supportLink}>support@substrata.me</a>
      </>
    );
  };

  const renderInvitationErrorModalDescription = () => {
    const props = {
      supportLink: {
        className: 'signup__email-link',
        href: 'mailto:support@substrata.me',
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    };

    return (
      <>
        This email address is not associated with an active invitation. Please
        use the email address you were invited with or contact support at{' '}
        <a {...props.supportLink}>support@substrata.me</a> for assistance.
      </>
    );
  };

  const attrs = {
    container: {
      className: 'signup',
    },
    left: {
      className: 'signup__left',
    },
    right: {
      className: 'signup__right',
    },
    title: {
      className: 'signup__title',
    },
    bottom: {
      className: 'signup__bottom',
    },
    googleButton: {
      size: ButtonSize.S,
      variant: ButtonVariant.Secondary,
      className: 'signup__google-button',
      disabled: !isInitialized || isLoggedIn || isSignInLoading,
      onClick: onGoogleSignIn,
    },
    footer: {
      className: 'signup__footer',
    },
    contactUsLink: {
      type: 'button' as const,
      className: 'signup__link',
      onClick: () => {
        window.open('https://www.substrata.me/company/contact/', '_blank');
        trackEvent('signup.homepage.open-contact-center-clicked');
        trackGAEvent('event', 'signup.homepage.open-contact-center-clicked');
      },
    },
    termsOfServiceLink: {
      type: 'button' as const,
      className: 'signup__link',
      onClick: () => {
        window.open('https://www.substrata.me/terms-of-service/', '_blank');
        trackEvent('signup.homepage.open-terms-of-use-clicked');
        trackGAEvent('event', 'signup.homepage.open-terms-of-use-clicked');
      },
    },
    privacyPolicyLink: {
      type: 'button' as const,
      className: 'signup__link',
      onClick: () => {
        window.open('https://www.substrata.me/privacy-policy/', '_blank');
        trackEvent('signup.homepage.open-privacy-policy-clicked');
        trackGAEvent('event', 'signup.homepage.open-privacy-policy-clicked');
      },
    },
    errorModal: {
      icon: WarningIcon,
      isOpened: isSignInError || isIdentityError,
      title: 'Could not log you in',
      description: renderErrorModalDescription(),
      onClose: () => {
        dispatch(clearSignInError());
      },
    },
    invitationErrorModal: {
      icon: WarningIcon,
      isOpened: isInvitationErrorModalOpened,
      title: 'Could not log you in',
      description: renderInvitationErrorModalDescription(),
      onClose: () => {
        dispatch(closeInvitationErrorModal());
      },
    },
  };

  return (
    <>
      <div {...attrs.container}>
        <div {...attrs.left}>
          <div {...attrs.title}>Welcome to Substrata</div>
          <div {...attrs.bottom}>
            <Button {...attrs.googleButton}>
              <GoogleIcon />
              <span>Continue with Google</span>
            </Button>
            <div {...attrs.footer}>
              <span>
                No Google account?{' '}
                <button {...attrs.contactUsLink}>Contact Us</button>
              </span>
              <span>
                By signing up with Google, you agree to our{' '}
                <button {...attrs.termsOfServiceLink}>Terms of Service</button>{' '}
                and <button {...attrs.privacyPolicyLink}>Privacy Policy</button>
              </span>
            </div>
          </div>
        </div>
        <div {...attrs.right} />
      </div>
      <ConfirmationModal {...attrs.errorModal} />
      <ConfirmationModal {...attrs.invitationErrorModal} />
    </>
  );
};

export default withPageTransition(Signup);
