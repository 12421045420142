import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Background, Button, ButtonSize } from '../../components';
import { CheckIcon, SpinnerIcon } from './icons';
import { Features } from './components';
import {
  STRIPE_PROFFESIONAL_ANNUAL_PLAN,
  STRIPE_PROFFESIONAL_MONTHLY_PLAN,
} from '../../constants/config';
import { useMixPanel } from '../../components/MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../../components/GoogleAnalytics/useGoogleAnalytics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTrialUsedStatus } from '../../store/auth/auth.thunks';
import { updateSelectedPlan } from '../../store/auth/auth.slice';
import { ROUTES } from '../../routes';
import { withPageTransition } from '../../hoc';
import { UserSignUpFlow } from '../../store/auth/auth.types';

const Pricing: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const userSignUpFlow = useAppSelector((state) => state.auth.userSignUpFlow);

  const [isAnnuallySelected, setIsAnnuallySelected] = useState(false);

  const history = useHistory();

  const { trackEvent, isMixPanelInitialized } = useMixPanel();
  const { trackGAEvent, isGoogleAnalyticsInitialized } = useGoogleAnalytics();

  const attrs = {
    container: {
      className: 'pricing',
    },
    content: {
      className: 'pricing__content',
    },
    title: {
      className: 'pricing__title',
    },
    header: {
      className: 'pricing__header',
    },
    tabsWrapper: {
      className: 'pricing__tabs-wrapper',
    },
    monthlyTab: {
      type: 'button' as const,
      disabled: !isAnnuallySelected,
      className: 'pricing__tab',
      onClick: () => {
        setIsAnnuallySelected(false);
      },
    },
    annuallyTab: {
      type: 'button' as const,
      disabled: isAnnuallySelected,
      className: 'pricing__tab',
      onClick: () => {
        setIsAnnuallySelected(true);
      },
    },
    plansWrapper: {
      className: 'pricing__plans-wrapper',
    },
    plan: {
      className: 'pricing__plan',
    },
    planHeader: {
      className: 'pricing__plan-header',
    },
    planDescription: {
      className: 'pricing__plan-description',
    },
    planPrice: {
      className: 'pricing__plan-price',
    },
    currencySign: {
      className: 'pricing__currency-sign',
    },
    discountDisclaimer: {
      className: 'pricing__discount-disclaimer',
    },
    planPriceDisclaimer: {
      className: 'pricing__plan-price-disclaimer',
    },
    professionalPlanButton: {
      size: ButtonSize.S,
      disabled: !user,
      className: 'pricing__plan-button',
      onClick: () => {
        dispatch(
          updateSelectedPlan({
            stripePlanInfo: isAnnuallySelected
              ? STRIPE_PROFFESIONAL_ANNUAL_PLAN
              : STRIPE_PROFFESIONAL_MONTHLY_PLAN,
            isAnnual: isAnnuallySelected,
            usersCount: 1,
            name: 'Professional',
          })
        );

        trackEvent('signup.pricing-page.subscribe-clicked');
        trackGAEvent('event', 'signup.pricing-page.subscribe-clicked');

        history.push(ROUTES.PAYMENT_DETAILS);
      },
    },
    spinnerIcon: {
      className: 'pricing__spinner-icon',
    },
    enterprisePlanButton: {
      size: ButtonSize.S,
      onClick: () => {
        window.open('https://www.substrata.me/get-a-demo', '_blank');
      },
    },
    planFeaturesWrapper: {
      className: 'pricing__plan-features-wrapper',
    },
    planFeatureTitle: {
      className: 'pricing__plan-feature-title',
    },
    planFeature: {
      className: 'pricing__plan-feature',
    },
  };

  const professionalPrice = useMemo(() => {
    const props = {
      container: {
        key: Date.now(),
        className: 'pricing__plan-price',
      },
      currencySign: {
        className: 'pricing__currency-sign',
      },
      discountDisclaimer: {
        className: 'pricing__discount-disclaimer',
      },
    };

    if (isAnnuallySelected) {
      return (
        <span {...props.container}>
          <span {...props.currencySign}>$</span>
          <span>
            {STRIPE_PROFFESIONAL_ANNUAL_PLAN.price -
              STRIPE_PROFFESIONAL_ANNUAL_PLAN.discount}
          </span>
          <span {...props.discountDisclaimer}>15% saved</span>
        </span>
      );
    }

    return (
      <span {...props.container}>
        <span {...props.currencySign}>$</span>
        <span>
          {STRIPE_PROFFESIONAL_MONTHLY_PLAN.price -
            STRIPE_PROFFESIONAL_MONTHLY_PLAN.discount}
        </span>
      </span>
    );
  }, [isAnnuallySelected]);

  const professionalPriceDescription = useMemo(() => {
    const props = {
      container: {
        key: Date.now() + 1,
        className: 'pricing__plan-price-disclaimer',
      },
    };

    if (isAnnuallySelected) {
      return <span {...props.container}>User/Year, Up to 10 Users</span>;
    }

    return <span {...props.container}>User/Month, Up to 10 Users</span>;
  }, [isAnnuallySelected]);

  useEffect(() => {
    if (isMixPanelInitialized) {
      trackEvent('signup.pricing-page');
    }
  }, [isMixPanelInitialized, trackEvent]);

  useEffect(() => {
    if (isGoogleAnalyticsInitialized) {
      trackGAEvent('pageview', 'signup.pricing-page');
    }
  }, [isGoogleAnalyticsInitialized, trackGAEvent]);

  useEffect(() => {
    if (user) {
      dispatch(getTrialUsedStatus());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (userSignUpFlow === UserSignUpFlow.COMPLETED) {
      history.replace(ROUTES.HOME);
    }
  }, [history, userSignUpFlow]);

  return (
    <div {...attrs.container}>
      <div {...attrs.content}>
        <div {...attrs.header}>
          <span {...attrs.title}>Plans & Pricing</span>
          <div {...attrs.tabsWrapper}>
            <button {...attrs.monthlyTab}>Monthly</button>
            <button {...attrs.annuallyTab}>Annually</button>
          </div>
        </div>
        <div {...attrs.plansWrapper}>
          <div {...attrs.plan}>
            <div {...attrs.planHeader}>
              <span {...attrs.title}>Professional</span>
              <span {...attrs.planDescription}>For revenue Professionals</span>
              {professionalPrice}
              {professionalPriceDescription}
            </div>
            <Button {...attrs.professionalPlanButton}>
              {!user ? (
                <SpinnerIcon {...attrs.spinnerIcon} />
              ) : (
                <span>Start for free</span>
              )}
            </Button>
            <div {...attrs.planFeaturesWrapper}>
              <div {...attrs.planFeatureTitle}>Features:</div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Q for Email</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Soundwave</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Temper</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Realtime Signals</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Activity and Analytics</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Webhooks</span>
              </div>
            </div>
          </div>
          <div {...attrs.plan}>
            <div {...attrs.planHeader}>
              <span {...attrs.title}>Enterprise</span>
              <span {...attrs.planDescription}>For large revenue teams</span>
              <span {...attrs.planPrice}>
                <span>Custom</span>
              </span>
              <span {...attrs.planPriceDisclaimer}>Price by Quote</span>
            </div>
            <Button {...attrs.enterprisePlanButton}>
              <span>Talk to sales</span>
            </Button>
            <div {...attrs.planFeaturesWrapper}>
              <div {...attrs.planFeatureTitle}>
                Everything in Professional, plus:
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>CRM Integration</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Workflow Integrations</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>Custom Onboarding & Training</span>
              </div>
              <div {...attrs.planFeature}>
                <CheckIcon />
                <span>GTM Success Manager</span>
              </div>
            </div>
          </div>
          <Background />
        </div>
      </div>
      <Features />
    </div>
  );
};

export default withPageTransition(Pricing);
