import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ArrowLeftIcon, ChromeIcon, LogoIcon } from './icons';
import { ROUTES } from '../../routes';
import { LayoutFooter } from './components';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { useMixPanel } from '../MixPanel/useMixPanel';
import { useGoogleAnalytics } from '../GoogleAnalytics/useGoogleAnalytics';
import { Scrollbar } from '../Scrollbar';
import { useAppSelector } from '../../store/hooks';

const Layout: React.FC = ({ children }) => {
  const isPaymentConfirmationLoading = useAppSelector(
    (state) => state.auth.isLoading.paymentConfirmation
  );

  const homePageMatch = useRouteMatch(ROUTES.HOME);
  const pricingPageMatch = useRouteMatch(ROUTES.PRICING);
  const paymentDetailsPageMatch = useRouteMatch(ROUTES.PAYMENT_DETAILS);
  const uninstallExtenstionPageMatch = useRouteMatch(ROUTES.Q_UNINSTALL);

  const history = useHistory();

  const { trackEvent } = useMixPanel();
  const { trackGAEvent } = useGoogleAnalytics();

  const showFooter = !!pricingPageMatch || !!uninstallExtenstionPageMatch;
  const showBackButton = !!homePageMatch?.isExact || !!paymentDetailsPageMatch;
  const showReinstallButton = !!uninstallExtenstionPageMatch;

  const attrs = {
    container: {
      className: 'layout',
    },
    header: {
      className: 'layout__header',
    },
    backButton: {
      size: ButtonSize.S,
      variant: ButtonVariant.Secondary,
      disabled: !!paymentDetailsPageMatch && isPaymentConfirmationLoading,
      className: 'layout__back-button',
      onClick: () => {
        if (homePageMatch?.isExact) {
          window.location.href = 'https://www.substrata.me/';
          trackEvent('signup.homepage.go-to-website-clicked');
          trackGAEvent('event', 'signup.homepage.go-to-website-clicked');
        } else if (paymentDetailsPageMatch) {
          history.push(ROUTES.PRICING);
        }
      },
    },
    logoLink: {
      className: `layout__logo-link${
        showReinstallButton ? ' layout__logo-link--no-margin' : ''
      }`,
      href: 'https://www.substrata.me/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    logo: {
      className: 'layout__logo',
    },
    reinstallButton: {
      size: ButtonSize.S,
      variant: ButtonVariant.Secondary,
      className: 'layout__reinstall-button',
      onClick: () => {
        window.open(
          'https://chromewebstore.google.com/detail/substrata-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
          '_blank'
        );
      },
    },
    contentWrapper: {
      className: 'layout__content-wrapper',
    },
    scrollable: {
      className: 'layout__scrollable',
    },
    content: {
      className: 'layout__content',
    },
  };

  return (
    <div {...attrs.container}>
      <header {...attrs.header}>
        {showBackButton ? (
          <Button {...attrs.backButton}>
            <ArrowLeftIcon />
            <span>Back</span>
          </Button>
        ) : null}
        <a {...attrs.logoLink}>
          <LogoIcon {...attrs.logo} />
        </a>
        {showReinstallButton ? (
          <Button {...attrs.reinstallButton}>
            <ChromeIcon />
            <span>Reinstall</span>
          </Button>
        ) : null}
      </header>
      <main {...attrs.contentWrapper}>
        <Scrollbar {...attrs.scrollable}>
          <div {...attrs.content}>
            {children}
            {showFooter ? <LayoutFooter /> : null}
          </div>
        </Scrollbar>
      </main>
    </div>
  );
};

export default Layout;
