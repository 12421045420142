import React from 'react';

const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50397 2.87198C5.94211 1.93311 6.88439 1.33301 7.92046 1.33301H8.07941C9.11548 1.33301 10.0578 1.93311 10.4959 2.87198L14.6719 11.8206C15.2907 13.1465 14.3228 14.6663 12.8595 14.6663H3.14033C1.67709 14.6663 0.709178 13.1465 1.32796 11.8206L5.50397 2.87198ZM7.99993 3.99967C8.36812 3.99967 8.6666 4.29815 8.6666 4.66634V9.33301C8.6666 9.7012 8.36812 9.99967 7.99993 9.99967C7.63174 9.99967 7.33327 9.7012 7.33327 9.33301V4.66634C7.33327 4.29815 7.63174 3.99967 7.99993 3.99967ZM7.99993 12.333C8.46017 12.333 8.83327 11.9599 8.83327 11.4997C8.83327 11.0394 8.46017 10.6663 7.99993 10.6663C7.5397 10.6663 7.1666 11.0394 7.1666 11.4997C7.1666 11.9599 7.5397 12.333 7.99993 12.333Z"
      fill="#F0384A"
    />
  </svg>
);

export default WarningIcon;
