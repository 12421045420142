import React, { useState } from 'react';
import { ArrowDownIcon, CrossIcon } from './icons';
import { CheckIcon } from '../../icons';
import { Tooltip } from '../../../../components';
import { FEATURES_LIST } from './constants';

const Features: React.FC = () => {
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const attrs = {
    container: {
      className: 'features',
    },
    button: {
      type: 'button' as const,
      className: `features__button${
        showAllFeatures ? ' features__button--active' : ''
      }`,
      onClick: () => {
        setShowAllFeatures((prevState) => !prevState);
      },
    },
    tableWrapper: {
      className: `features__table-wrapper${
        !showAllFeatures ? ' features__table-wrapper--hidden' : ''
      }`,
      onTransitionEnd: (e: React.TransitionEvent<HTMLDivElement>) => {
        if (showAllFeatures) {
          e.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      },
    },
    table: {
      className: `features__table`,
    },
    headerRow: {
      className: 'features__row features__row--header',
    },
    cell: {
      className: 'features__cell',
    },
  };

  const renderFeatures = () =>
    FEATURES_LIST.map(({ isFunctionality, isIncluded, name, tooltip }) => {
      const props = {
        row: {
          key: name,
          className: `features__row${
            isFunctionality ? '' : ' features__row--subheader'
          }`,
        },
        nameCell: {
          className: `features__cell${
            tooltip ? '' : ' features__cell--no-tooltip'
          }`,
        },
        tooltip: {
          text: tooltip,
          width: 256,
        },
      };

      return (
        <div {...props.row}>
          <div {...props.nameCell}>
            {tooltip ? <Tooltip {...props.tooltip} /> : null}
            <span>{name}</span>
          </div>
          <div {...attrs.cell}>
            {isIncluded ? <CheckIcon /> : <CrossIcon />}
          </div>
          <div {...attrs.cell}>
            <CheckIcon />
          </div>
        </div>
      );
    });

  return (
    <div {...attrs.container}>
      <button {...attrs.button}>
        <span>View All Features</span>
        <ArrowDownIcon />
      </button>
      <div {...attrs.tableWrapper}>
        <div {...attrs.table}>
          <div {...attrs.headerRow}>
            <div {...attrs.cell}>Compare plans</div>
            <div {...attrs.cell}>Professional</div>
            <div {...attrs.cell}>Custom</div>
          </div>
          {renderFeatures()}
        </div>
      </div>
    </div>
  );
};

export default Features;
