import React from 'react';

const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="#18222F"
    />
    <path d="M14.7758 28H10L16.3978 12H21.1736L14.7758 28Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0958 27.0111L19.7028 12.9889H17.0777L11.4708 27.0111H14.0958ZM14.7758 28L21.1736 12H16.3978L10 28H14.7758Z"
      fill="white"
    />
    <path d="M23.6019 28H18.8262L25.224 12H29.9997L23.6019 28Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.922 27.0111L28.529 12.9889H25.9039L20.2969 27.0111H22.922ZM23.6019 28L29.9997 12H25.224L18.8262 28H23.6019Z"
      fill="white"
    />
  </svg>
);

export default LogoIcon;
