import React from 'react';

const SpinnerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM4.0446 16C4.0446 22.6028 9.39721 27.9554 16 27.9554C22.6028 27.9554 27.9554 22.6028 27.9554 16C27.9554 9.39721 22.6028 4.0446 16 4.0446C9.39721 4.0446 4.0446 9.39721 4.0446 16Z"
      fill="#F1F4F8"
    />
    <path
      d="M29.9791 16C31.0952 16 32.0132 15.0916 31.8726 13.9844C31.4282 10.4843 29.8352 7.2078 27.3137 4.68629C24.3131 1.68571 20.2435 3.20373e-07 16 0C11.7565 -3.20373e-07 7.68687 1.68571 4.68629 4.68629C2.16478 7.2078 0.571795 10.4843 0.127402 13.9844C-0.0131736 15.0916 0.904774 16 2.02087 16C3.13696 16 4.02432 15.0888 4.21205 13.9887C4.62585 11.5635 5.78145 9.30701 7.54423 7.54423C9.78684 5.30162 12.8285 4.04174 16 4.04174C19.1715 4.04174 22.2132 5.30162 24.4558 7.54423C26.2186 9.30701 27.3741 11.5635 27.788 13.9887C27.9757 15.0889 28.863 16 29.9791 16Z"
      fill="#44536A"
    />
  </svg>
);

export default SpinnerIcon;
