import React from 'react';

const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7073 7.2931C19.0977 7.68374 19.0975 8.31691 18.7069 8.70731L10.7022 16.7073C10.5146 16.8948 10.2601 17.0001 9.99485 17C9.72959 16.9999 9.47523 16.8944 9.28777 16.7067L5.29248 12.7067C4.90218 12.3159 4.90256 11.6828 5.29331 11.2925C5.68406 10.9022 6.31723 10.9026 6.70752 11.2933L9.99592 14.5856L17.2931 7.29269C17.6837 6.90228 18.3169 6.90246 18.7073 7.2931Z"
      fill="#10B981"
    />
  </svg>
);

export default CheckIcon;
